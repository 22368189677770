<template>
  <div class="index">
    <div>
     <!-- banner -->
      <el-carousel height="450px" class="indexBanner">
        <el-carousel-item v-for="(item, index) in this.$store.getters.banner" :key="index">
          <img :src="item.pic" alt="" style="width: 100%; height: 100%" />
        </el-carousel-item>
      </el-carousel>
      <!-- 公告 -->
      <el-carousel height="50px" direction="vertical" class="indexNotice">
        <el-carousel-item v-for="(item, index) in $store.getters.rollList" :key="index">
          <h3 class="medium">
            <span>网站公告：</span> <span>{{ item.title }}</span>
            <span>{{ item.createTime }}</span>
            <span @click="handleCommandAS" style=" cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span>
          </h3>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="breadcrumb">
      <div>首页</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>活动管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-row
        v-for="(item, index) in brandlist"
        :key="index"
        class="containerItem"
      >
        <el-col :span="12" class="containerImg">
          <img :src="item.imageInput" alt="" />
        </el-col>
        <el-col :span="12" class="containerInfo">
          <h3>{{ item.title }}</h3>
          <p class="date"><i class="el-icon-time"></i>发布时间：{{item.createTime}}</p>
          <p>{{ item.synopsis }}</p>
          <el-button type="primary" @click.native="toDetail(item.id)" >查看详情</el-button>
        </el-col>
      </el-row>
       <el-pagination style="text-align:center;padding: 0 0 15px 0;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
  </div>
</template>
<script>
import {apiGetArticleType,apiGetArticleByType} from '@/api/arcticle'

export default {
  name: "actions",
  data() {
    return {
       brandlist: [],
        pageSize: 10,
        total: 0,
        pageNo: 1
    };
  },
   mounted(){
    this.getTypeList()
    this.getArticleList()
  },
  methods:{
    handleCommandAS(){
      this.$router.push({name: 'joiningNotice'})
      this.$store.commit('SET_INFO',['menuIndex','joining'])
    },
    getTypeList(){
      apiGetArticleType().then(res=>{
        console.log(res);
      })
    },
    // getArticleList(){
    //   apiGetArticleByType({cid: 507}).then(res=>{
    //     console.log(res);
    //     if(res.code == 200){
    //       this.brandlist = res.data.list
    //     }
    //   })
    // },
     getArticleList(){
      apiGetArticleByType({cid: 507,page: this.pageNo,limit: this.pageSize}).then(res=>{
        console.log(res);
          if(res.code == 200){
          this.brandlist = res.data.list
          this.total =  res.data.total
        }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.pageNo =1
      this.getArticleList()
    },
     handleCurrentChange(val){
      this.pageNo =val
      this.getArticleList()
    },
    //查看详情
    toDetail(id){
      console.log(id);
      this.$router.push({name:'articleDetail',query:{id,path:'/actions',title:'品牌动态'}})
    }
  }
};
</script>
<style lang="scss" scoped>
.index {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
}
.indexBanner,
.indexNotice,
.container {
  background-color: #fff;
  margin-bottom: 10px;
}
.breadcrumb {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px;
  align-items: center;
  border-bottom: 3px solid#f1f2f3;
}
.medium {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 80px;
}
.container {
  margin-bottom: 20px;
  .containerItem {
    border-bottom: 10px solid#f5f6f7;
  }
}
.containerImg {
  height: 360px;
  img {
    width: 100%;
    height: 100%;
  }
}
.containerInfo {
  height: 360px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 30px 50px;
  h3 {
    line-height: 26px;
    font-size: 18px;
    margin-bottom: 15px;
  }
  p {
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 15px;
  }
  .date{
      color: #999;
      display: flex;
      align-items: center;
  }
  .el-button{
      width: 100px;
  }
}
</style>